import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = {
  key: 0,
  class: "ion-padding",
  style: {"white-space":"pre-wrap"}
}
const _hoisted_3 = {
  key: 1,
  class: "ion-padding",
  style: {"white-space":"pre-wrap"}
}
const _hoisted_4 = { key: 5 }
const _hoisted_5 = { class: "ion-padding-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.loading ? '...' : $setup.booking.date), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/bookings" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : _createCommentVNode("", true),
          (!$setup.loading && $setup.booking)
            ? (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                fixed: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { size: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card, { class: "ion-text-center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_header, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_card_title, { color: "medium" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString($setup.t('bookingDetails')), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_card_title, { color: "primary" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString($setup.booking.date) + " " + _toDisplayString($setup.booking.startTime.split(":").slice(0, -1).join(":")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_card_title, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_chip, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_label, null, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString($setup.booking.duration) + " " + _toDisplayString($setup.t('minutes')), 1)
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_card_content, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_badge, {
                                            color: $setup.getBookingStatusColor($setup.booking.status)
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString($setup.t($setup.booking.status)), 1)
                                            ]),
                                            _: 1
                                          }, 8, ["color"]),
                                          ($setup.booking.providedServices)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.t('bookedServices')) + _toDisplayString($setup.booking.providedServices.split(" , ").map(s => s.split(" - ")[0]).join(" , ")), 1))
                                            : _createCommentVNode("", true),
                                          ($setup.booking.customerNote)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.t('remark')) + ": " + _toDisplayString($setup.booking.customerNote), 1))
                                            : _createCommentVNode("", true),
                                          (!$setup.isBookingCompleted($setup.booking))
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 2,
                                                class: "ion-margin-bottom",
                                                expand: "block",
                                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.showBookingQRCode($setup.booking)), ["stop"]))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, {
                                                    slot: "start",
                                                    icon: $setup.qrCodeOutline
                                                  }, null, 8, ["icon"]),
                                                  _createTextVNode(" " + _toDisplayString($setup.t('bookingQRCode')), 1)
                                                ]),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          ($setup.canUpdateBooking($setup.booking))
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 3,
                                                color: "tertiary",
                                                class: "ion-margin-bottom",
                                                expand: "block",
                                                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.updateBooking($setup.booking)), ["stop"]))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, {
                                                    slot: "start",
                                                    icon: $setup.calendarOutline
                                                  }, null, 8, ["icon"]),
                                                  _createTextVNode(" " + _toDisplayString($setup.t('changeBookingTime')), 1)
                                                ]),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          ($setup.canCancelBooking($setup.booking))
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 4,
                                                color: "danger",
                                                expand: "block",
                                                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.cancelBooking($setup.booking)), ["stop"]))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, {
                                                    slot: "start",
                                                    icon: $setup.close
                                                  }, null, 8, ["icon"]),
                                                  _createTextVNode(" " + _toDisplayString($setup.t('cancelBooking')), 1)
                                                ]),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          ($setup.isBookingCompleted($setup.booking))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                                _createVNode(_component_ion_title, { class: "ion-text-center" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString($setup.t('beforeTreatment')), 1)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_row, null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.booking.beforePhotoLinks.split(';'), (photoLink) => {
                                                      return (_openBlock(), _createBlock(_component_ion_col, {
                                                        "size-xs": "6",
                                                        "size-md": "4",
                                                        key: photoLink
                                                      }, {
                                                        default: _withCtx(() => [
                                                          photoLink
                                                            ? (_openBlock(), _createBlock(_component_ion_img, {
                                                                key: 0,
                                                                src: photoLink
                                                              }, null, 8, ["src"]))
                                                            : _createCommentVNode("", true)
                                                        ]),
                                                        _: 2
                                                      }, 1024))
                                                    }), 128))
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_title, { class: "ion-text-center" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString($setup.t('afterTreatment')), 1)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_row, null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.booking.afterPhotoLinks.split(';'), (photoLink) => {
                                                      return (_openBlock(), _createBlock(_component_ion_col, {
                                                        "size-xs": "6",
                                                        "size-md": "4",
                                                        key: photoLink
                                                      }, {
                                                        default: _withCtx(() => [
                                                          photoLink
                                                            ? (_openBlock(), _createBlock(_component_ion_img, {
                                                                key: 0,
                                                                src: photoLink
                                                              }, null, 8, ["src"]))
                                                            : _createCommentVNode("", true)
                                                        ]),
                                                        _: 2
                                                      }, 1024))
                                                    }), 128))
                                                  ]),
                                                  _: 1
                                                })
                                              ]))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("p", _hoisted_5, [
                                            _createElementVNode("small", null, _toDisplayString($setup.t('PostPage.lastUpdated')) + _toDisplayString($setup.formatDate($setup.booking.updatedAt)), 1)
                                          ])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}