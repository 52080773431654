
// Vue reactivity
import { computed } from "vue";

// icons
import { star, starHalfOutline, starOutline, calendarOutline, pencil, close, qrCodeOutline } from "ionicons/icons";

// components
import { IonPage, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent,
        IonGrid, IonSpinner, IonRow, IonCol, IonButtons, IonButton,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
        IonIcon, IonAvatar, IonLabel, IonItem, IonChip, IonNote, IonBadge, IonImg,
        loadingController, alertController } from "@ionic/vue";

// API services
import BookingService from '@/services/BookingService';

import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: "BookingDetailPage",
  components: {
    IonPage, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent,
    IonGrid, IonSpinner, IonRow, IonCol, IonButtons, IonButton,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
    IonIcon, IonAvatar, IonLabel, IonItem, IonChip, IonNote, IonBadge, IonImg,
  },
  setup() {
    // methods or filters
    const store = useStore();
    const { t } = useI18n();
    const { BOOKING_STATUSES, getBookingStatusColor, formatDate, presentToast } = utils();
    const route = useRoute();
    const currId = route.params.id;

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingBookings);
    const booking = computed(() => store.getters.getBookingById(currId));

    const cancelBooking = async (booking: any) => {
      const alert = await alertController.create({
        header: t('confirmCancel'),
        message: t('confirmCancelBooking'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: t('cancelBooking'),
            handler: async () => {
              const loading = await loadingController.create({});
              await loading.present();
              const res = await BookingService.cancelBooking(booking.id);
              store.commit('updateBooking', res[0]);
              loading.dismiss();
              presentToast( t('bookingCancelled'), 2000 );
            },
          },
        ],
      });
      return alert.present();
    }

    const canUpdateBooking = (booking: any) => {
      return ![BOOKING_STATUSES.complete, BOOKING_STATUSES.cancel].includes(booking.status);
    }
    const canCancelBooking = (booking: any) => {
      return ![BOOKING_STATUSES.complete, BOOKING_STATUSES.cancel].includes(booking.status);
    }
    const isBookingCompleted = (booking: any) => {
      return booking.status == BOOKING_STATUSES.complete;
    }

    const showBookingQRCode = async (booking: any) => {
      const alert = await alertController.create({
        header: t('bookingQRCode'),
        message: `<ion-img src="https://qrcode.tec-it.com/API/QRCode?data=${booking.id}&dpi=96"></ion-img>`,
        buttons: [
          {
            text: t('back'),
            role: 'cancel',
            cssClass: 'secondary'
          },
        ],
      });
      return alert.present();
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, formatDate, cancelBooking, canUpdateBooking, canCancelBooking, getBookingStatusColor, showBookingQRCode, isBookingCompleted,

      // icons
      star, starHalfOutline, starOutline, pencil, close, calendarOutline, qrCodeOutline,

      // variables
      loading, booking,
    };
  },
};
